import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import React, { useLayoutEffect, useRef, useState } from 'react';

import UI from '#front-credpago';

import LogoCredsignPng from '../../../images/credSing.svg';
import LogoMultiPgtoPng from '../../../images/multipagamento.svg';
import LogoSeguro from '../../../images/seguro.svg';
import { Container, FacilidadesWrapper, ListaFacilidades, ListaFacilidades2 } from '../style';

const Facilidade = ({
  type,
  logo,
  logoWidth,
  logoHeight,
  desc,
  onClickButton,
  buttonType,
  image,
  isInvertedBtn,
  addMt,
}) => {
  const [isOpen, setOpen] = useState(true);
  const textRef = useRef(null);

  const openParagraph = (e) => {
    e.preventDefault();
    setOpen(true);
    return false;
  };

  useLayoutEffect(() => {
    // calculating height
    if (textRef.current.getBoundingClientRect().height > 66) {
      setOpen(false);
    }
  }, []);

  return (
    <div className={`facilidade-box ${type}`}>
      <div className="text-wrapper">
        <img
          decoding="async"
          className="logo"
          loading="lazy"
          src={logo}
          alt={desc}
          width={logoWidth}
          height={logoHeight}
        />
        <p className={isOpen ? 'opened' : 'closed'}>
          <span ref={(ref) => (textRef.current = ref)} className="typo">
            {desc}
          </span>
          <br />
          {!isOpen && (
            // eslint-disable-next-line
            <a className="link-more" onClick={openParagraph} href="#">
              Mais +
            </a>
          )}
        </p>

        <div style={{ marginTop: addMt ? `${addMt}px` : 0 }}>
          <UI.Button onClick={onClickButton} inverted={isInvertedBtn || false} type={buttonType || 'secondary'}>
            Saiba mais
          </UI.Button>
        </div>
      </div>
      <div className="img-wrapper">
        <Img fluid={image} />
      </div>
    </div>
  );
};

const Facilidades = ({ data }) => {
  const windowObjExists = typeof window !== 'undefined';

  const navigateLink = (link) => {
    if (windowObjExists) {
      window.open(link, '_blank');
    }
  };

  return (
    <FacilidadesWrapper id="produtos-credpago">
      <Container>
        <UI.Row>
          <UI.Col style={{ textAlign: 'center' }}>
            <UI.Title
              level={2}
              fontWeight={700}
              splitLabel={['Uma família de facilidades', <br key={0} />, null, 'para simplificar sua vida']}
              style={{ marginBottom: 45 }}
            />

            <ListaFacilidades>
              <Facilidade
                logo={LogoMultiPgtoPng}
                logoWidth={112}
                logoHeight={69}
                type="multiPagamento"
                desc="Diferentes opções para alugar com CredPago sem necessidade do cartão de crédito."
                onClickButton={() => navigate('/atendimento#multipagamento')}
                image={data.ImgMultiPgto.childImageSharp.fluid}
                addMt={81}
              />

              <Facilidade
                logo={LogoCredsignPng}
                logoWidth={170}
                logoHeight={60}
                type="credSign"
                desc="Assinatura eletrônica de qualquer documento com validade jurídica."
                onClickButton={() => navigateLink('/credsign')}
                image={data.ImgCredSign.childImageSharp.fluid}
                addMt={49}
              />
            </ListaFacilidades>

            <ListaFacilidades2 style={{ display: 'block' }}>
              <Facilidade
                logo={LogoSeguro}
                logoWidth={170}
                logoHeight={60}
                type="seguro"
                desc="Mais segurança aos imóveis alugados pelo menor preço do mercado. O Seguro Residencial da CredPago oferece cobertura contra raio, incêndio e explosão."
                onClickButton={() => navigateLink('/seguro')}
                image={data.ImgSeguro.childImageSharp.fluid}
              />
            </ListaFacilidades2>
          </UI.Col>
        </UI.Row>
      </Container>
    </FacilidadesWrapper>
  );
};

export default Facilidades;
