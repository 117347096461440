import styled from 'styled-components';

import UI from '#front-credpago';

export const PictureParceriaEmpoderar = styled.section`
  max-width: 524px;
  text-align: center;
  margin: 0 0 0 auto;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    display: none !important;
  }
`;
