import React from 'react';

import UI from '#front-credpago';

import { Container, PersonasWrapper } from '../style';
import AbaImobiliarias from './imobiliarias';
import AbaInquilinos from './inquilinos';
import AbaProprietarios from './proprietarios';
import { TabsContainer } from './styles';

const Personas = ({ data }) => {
  return (
    <PersonasWrapper id="vantagens">
      <Container>
        <UI.Row>
          <UI.Col responsive={{ xs: 12, sm: 12, md: 8, xl: 8 }}>
            <UI.Title
              level={2}
              fontWeight={700}
              // eslint-disable-next-line
              splitLabel={[' Vantagens para', <br />, null, 'inquilinos, proprietários e imobiliárias']}
            />
          </UI.Col>
        </UI.Row>
        <UI.Row>
          <TabsContainer>
            <UI.Tabs>
              <UI.Tab title="Inquilinos">
                <AbaInquilinos img={data.AbaInquilinos.childImageSharp.fluid} />
              </UI.Tab>
              <UI.Tab title="Imobiliárias">
                <AbaImobiliarias img={data.AbaImobiliarias.childImageSharp.fluid} />
              </UI.Tab>
              <UI.Tab title="Proprietários">
                <AbaProprietarios img={data.AbaProprietarios.childImageSharp.fluid} />
              </UI.Tab>
            </UI.Tabs>
          </TabsContainer>
        </UI.Row>
      </Container>
    </PersonasWrapper>
  );
};

export default Personas;
