import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import Hero from '../components/hero';
import Layout from '../components/layout/index';
import BlocoBtg from '../components/partials/btg';
import CredPagoNaMidia from '../components/partials/credpagoNaMidia';
import BlocoDepoimentos from '../components/partials/depoimentos';
import BlocoFacilidades from '../components/partials/facilidades';
import TabsPersonas from '../components/partials/personas';
import SEO from '../components/seo';

export const query = graphql`
  query {
    Hero: file(relativePath: { eq: "cp-family-hero.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    AbaInquilinos: file(relativePath: { eq: "illustrations/aba-inquilinos.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    AbaImobiliarias: file(relativePath: { eq: "illustrations/aba-imobiliarias.png" }) {
      childImageSharp {
        fluid(maxWidth: 695, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    AbaProprietarios: file(relativePath: { eq: "illustrations/aba-proprietarios.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ParceriaEmpoderar: file(relativePath: { eq: "parceria-empoderar.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ImgSimLocacao: file(relativePath: { eq: "hand-key.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ImgMultiPgto: file(relativePath: { eq: "multipagamento-flags.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ImgCredSign: file(relativePath: { eq: "credsign-hand.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ImgSeguro: file(relativePath: { eq: "seguro-family.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    imgCredpago360Person: file(relativePath: { eq: "credpago-360-person.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    ImgMapa: file(relativePath: { eq: "map-with-pin.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <>
    <Layout>
      <SEO title="CredPago" />
      <Hero data={data} />
      <TabsPersonas data={data} />
      <BlocoFacilidades data={data} />
      <BlocoBtg data={data} />
      <CredPagoNaMidia />
      <BlocoDepoimentos />
    </Layout>

    <Helmet>
      <script type="text/javascript">
        {`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2908098,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script>
    </Helmet>
  </>
);

export default IndexPage;
