import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import UI from '#front-credpago';

import { QueroSerParceiraContainer } from './styles';

const QueroSerParceiraBox = () => {
  return (
    <QueroSerParceiraContainer>
      <p className="title">
        <strong>
          Com CredPago, <br /> você pode mais
        </strong>
      </p>
      <p className="description">
        Cadastre-se agora mesmo e <br /> faça parte da revolução do <br /> mercado imobiliário
      </p>
      <UI.Button onClick={() => navigate('/cadastre-se')}>Quero ser parceira</UI.Button>
    </QueroSerParceiraContainer>
  );
};

const Imobiliarias = ({ img }) => {
  return (
    <UI.Row style={{ alignItems: 'center' }}>
      <UI.Col responsive={{ xs: 12, sm: 6, lg: 4 }}>
        <UI.Title level={5} fontWeight={600}>
          Imobiliárias
        </UI.Title>
        <UI.Title level={6} fontWeight={400} style={{ maxWidth: 300 }}>
          Custo zero para imobiliárias e corretores
        </UI.Title>
        <ul>
          <li>Análise de crédito eficiente contra inadimplência</li>
          <li>Garantia de recebimento (até 30x o valor do aluguel)</li>
          <li>Maior taxa de conversão em aluguéis</li>
          <li>Sem burocracias, 100% digital</li>
          <li>Suporte de marketing para divulgação</li>
        </ul>
      </UI.Col>
      <UI.Col responsive={{ xs: 12, sm: 6, lg: 4 }}>
        <Img fluid={img} loading="lazy" />
      </UI.Col>
      <UI.Col responsive={{ xs: 12, sm: 12, lg: 4 }}>
        <div style={{ textAlign: 'center', paddingTop: '1.4em' }}>
          <QueroSerParceiraBox />
        </div>
      </UI.Col>
    </UI.Row>
  );
};

export default Imobiliarias;
