import styled, { css } from 'styled-components';

import UI from '#front-credpago';

import BgLines from '../../images/cp-lines.png';
import BgLinesInverse from '../../images/cp-lines-inverse.png';
import globals from '../globals';

const { containerMaxWidth, fonts } = globals;
export const FullWidthWrapper = styled.section`
  display: flex;
  height: 100%;
  height: 100vh;
  background: url(${BgLines}) no-repeat center center;
  background-size: 100% 90%;
  position: relative;

  @media ${UI.globals.mediaqueries.xs} {
    margin-top: 40px;
    height: 700px;
    background-size: auto 100%;
  }

  @media ${UI.globals.mediaqueries.md} {
    height: ${(props) => (props.smallOnMdScreen ? '700px' : 'inherit')};
  }

  .text-wrapper {
    align-self: center;

    & > .col-inner {
      @media ${UI.globals.mediaqueries.md} {
        padding-right: 0;
      }

      h1 {
        display: flex;
        flex-direction: column;

        .splitted-span {
          white-space: nowrap;
        }
      }
    }

    p {
      color: #7d7d7d;
      line-height: 1.5;
      margin: 2em 0;
      max-width: 550px;

      @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
        margin: 1em 0;
      }
    }

    button {
      font-family: ${UI.globals.fonts.default};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 0 auto;
  align-self: center;
  position: relative;

  .margin-top-col {
    margin-top: 110px;
  }

  @media ${UI.globals.mediaqueries.md} {
    .margin-top-col {
      margin-top: 70px;
    }
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    .margin-top-col {
      margin-top: 0px;
    }
  }
`;

export const FixedBottom = styled.div`
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  font-family: ${fonts.default};

  & > span {
    align-self: center;
    margin-left: 20px;
    font-size: 13px;
    color: #7d7d7d;
  }

  .icon-wrapper svg {
    width: 13px;
  }
`;

export const HeroPlanosTarifasWrapper = styled.section`
  display: flex;
  min-height: 669px;
  background: url(${BgLinesInverse}) no-repeat center center;
  background-size: cover;
  background-position-y: -125px;
  background-position-x: -140px;

  @media ${UI.globals.mediaqueries.xs} {
    margin-top: 6rem;
    background-image: none;
    /* background-size: auto 100%;
    background-position-y: -20px; */

    .icone-svg {
      width: 23% !important;
      margin: auto 1% !important;
    }
  }

  @media ${UI.globals.mediaqueries.md} {
    height: 700px;
    background-position-y: 78px;
    background-position-x: -70px;

    .icone-svg {
      width: 21% !important;
      margin: auto 2% !important;
    }
  }

  @media ${UI.globals.mediaqueries.sm} {
    height: 520px;
    background-position-y: 90px;
    background-position-x: -70px;
  }

  @media only screen and (min-width: 1441px) {
    background-position-y: -30px;
    background-position-x: -142px;
  }

  @media only screen and (width: 1280px) {
    background-position-y: -75px;
    background-position-x: -90px;
  }

  @media only screen and (width: 1024px) {
    background-position-y: -70px;
    background-position-x: -210px;
  }

  .main-row {
    align-self: center;

    & > .col {
      @media ${UI.globals.mediaqueries.md} {
        margin-left: auto;
        margin-right: auto;
      }
    }

    h2 {
      /* fix especifico pra alturas baixas de monitor */
      @media (max-height: 700px) {
        padding-top: 40px;
      }

      & + p {
        margin-top: 1em;
      }
    }
  }

  .picture-wrapper {
    margin: auto;
    padding: 0;
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
      display: none;
    }

    & > .col-inner {
        padding: 0;
      }
    }
  }
`;

export const TarifasList = styled.ul`
  margin: 1em 0;
  padding: 0;
  list-style: none;
`;

export const TarifaWrapper = styled.li`
  max-width: 700px;
  color: white;
  display: grid;
  grid-template-columns: 170px 1fr;
  text-align: center;
  overflow: hidden;

  & + li {
    padding: 10px 0 0 0;
    margin-top: 0.3em;
  }

  .logo {
    width: 100%;
    margin: 27px 0 0 auto;
  }

  .type-table {
    .table-like {
      border: 1px solid ${UI.globals.colors.brandDarkBlue};
      border-radius: 16px 16px 16px 0;
      background: #f3f3f3;
      color: ${UI.globals.colors.brandDarkBlue};
      overflow: hidden;
      display: grid;
      grid-template-columns: 4fr 5fr;
      grid-template-rows: auto 60px;

      & > div {
        border: 1px solid ${UI.globals.colors.brandDarkBlue};
      }

      .table-title {
        font-weight: bold;
        padding: 4px 0;
        font-size: 1.2em;
      }

      .table-body {
        padding: 0px 15px;
        font-weight: 300;
        font-size: 1.1em;
        display: grid;

        @media ${UI.globals.mediaqueries.lg}, ${UI.globals.mediaqueries.md} {
          font-size: 1em;
        }

        @media ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.xs} {
          font-size: 0.85em;
          padding: 0px 6px;
        }

        p {
          display: flex;
          text-align: left;
          margin: 0 auto;
          padding: 0;
          align-self: center;
          justify-content: center;
          align-items: center;
          line-height: 1.2;
          max-width: 220px;

          strong {
            font-size: 40px;
            color: ${UI.globals.colors.brandDarkBlue};
            font-weight: 900;
            margin-right: 10px;
            line-height: 13px;
            letter-spacing: 0px;
            opacity: 1;

            @media ${UI.globals.mediaqueries.lg}, ${UI.globals.mediaqueries.md} {
              font-size: 40px;
            }

            @media ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.xs} {
              font-size: 40px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }

  @media ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.xs} {
    grid-template-columns: repeat(1, 1fr);

    .logo {
      width: 50%;
    }

    .type-table {
      .table-like {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto 60px;
      }
    }
  }

  @media only screen and (min-width: 500px) and (max-width: 768px) {
    grid-template-columns: 170px 1fr;

    .logo {
      width: 100%;
    }
  }

  @media only screen and (min-width: 400px) and (max-width: 500px) {
    grid-template-columns: 110px 1fr;

    .logo {
      width: 100%;
    }
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    line-height: 0.9;

    & + p.description {
      color: #7d7d7d;
      font-size: 15px;
      text-align: left;
      max-width: 600px;
      line-height: 20px;
      margin-top: 2em;
      margin-bottom: 3em;
    }
  }

  small {
    color: #7d7d7d;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const WrapperGuarantees = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 20px;
  max-width: 600px;
  align-content: center;

  @media screen and (max-width: 575px) {
    grid-gap: 10px;
  }
`;

export const ImgGuarantees = styled.img`
  @media screen and (max-width: 575px) {
    width: 70%;
    height: 70%;
  }
`;

export const Guarantees = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const warrantyInformationModifiers = {
  fit: () => css`
    color: #fcc90e;
    border: 1px solid #fcc90e;
  `,

  fitplus: () => css`
    color: #ffcd0e;
    border: 1px solid #ffcd0e;
  `,

  smart: () => css`
    color: #1587ba;
    border: 1px solid #1587ba;
  `,

  smartplus: () => css`
    color: #57cbf5;
    border: 1px solid #57cbf5;
  `,

  up: () => css`
    color: #208b43;
    border: 1px solid #208b43;
  `,
};

export const WarrantyInformation = styled.div`
  ${({ type }) => css`
    padding: 10px 30px;
    margin-top: 5px;
    border-radius: 18px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 575px) {
      font-size: 14px;
      padding: 5px 20px;
      border-radius: 10px;
    }
    ${type === 'fit' && warrantyInformationModifiers.fit()};
    ${type === 'fitplus' && warrantyInformationModifiers.fitplus()};
    ${type === 'smart' && warrantyInformationModifiers.smart()};
    ${type === 'smartplus' && warrantyInformationModifiers.smartplus()};
    ${type === 'up' && warrantyInformationModifiers.up()};
  `}
`;

export const WarrantyDescription = styled.p`
  color: #7d7d7d;
  font-size: 13px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 575px) {
    font-size: 10px;
  }
`;
