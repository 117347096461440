import 'swiper/swiper-bundle.css';

import React, { useEffect, useRef, useState } from 'react';
import Swiper, { Navigation, Pagination } from 'swiper';

import UI from '#front-credpago';

import LogoCasaVogue from '../../images/logos/midia/casa-vogue.png';
import LogoEpoca from '../../images/logos/midia/epoca-negocios.png';
import LogoEstadao from '../../images/logos/midia/estadao.png';
import LogoExame from '../../images/logos/midia/exame.png';
import LogoFolha from '../../images/logos/midia/folha.png';
import LogoForbes from '../../images/logos/midia/forbes.png';
import LogoGlobo from '../../images/logos/midia/o-globo.png';
import LogoPegn from '../../images/logos/midia/pegn.png';
import LogoValor from '../../images/logos/midia/valor-economico.png';
import { Container, MidiaWrapper, SlideText } from './style';

const midiaItems = [
  {
    name: 'Forbes',
    logo: LogoForbes,
    desc: 'Loft compra fintech CredPago e expande portfólio de serviços',
    link: '/midia/FORBES_PDF_CREDPAGO.pdf',
  },
  {
    name: 'Estadão',
    logo: LogoEstadao,
    desc: 'Loft compra fintech CredPago de olho em serviços financeiros',
    link: '/midia/ESTADAO_PDF.pdf',
  },
  {
    name: 'Valor Econômico',
    logo: LogoValor,
    desc: 'Loft anuncia fusão com CredPago e BTG fica com fatia na companhia',
    link: '/midia/VALOR_PDF.pdf',
  },
  {
    name: 'Época Negócios',
    logo: LogoEpoca,
    desc: 'Fusão entre Loft e CredPago mira liderança de mercado',
    link: '/midia/EPOCA_PDF.pdf',
  },
  {
    name: 'Casa Vogue',
    logo: LogoCasaVogue,
    desc: 'Com o uso da tecnologia, startup elimina o fiador em contrados imobiliários',
    link: '/midia/CASAVOGUE_PDF_ESTADAO.pdf',
  },
  {
    name: 'Radar Imobiliário',
    logo: LogoEstadao,
    desc: 'Startups avançam no mercado com soluções para facilitar a compra ou aluguel de imóveis',
    link: '/midia/ESTADAO_PDF_CREDPAGO.pdf',
  },
  {
    name: 'Exame',
    logo: LogoExame,
    desc: 'CredPago: o plano para ter R$ 6 bi em crédito imobiliário com a Loft',
    link: '/midia/Exame_PDF_CREDPAGO.pdf',
  },
  {
    name: 'Folha de S. Paulo',
    logo: LogoFolha,
    desc: 'Com venda da CredPago, BTG vira sócio da Loft',
    link: '/midia/FOLHASPAULO_PDF_CREDPAGO.pdf',
  },
  {
    name: 'O Globo',
    logo: LogoGlobo,
    desc: 'Loft compra startup CredPag, de aluguel de imóveis',
    link: '/midia/O_GLOBO_PDF.pdf',
  },
  {
    name: 'Pequenas Empresas Grandes Negócios',
    logo: LogoPegn,
    desc: 'BTG compra 20% da CredPago, startup que atua como fiador em contratos de locação',
    link: '/midia/PEGN_PDF_CREDPAGO.pdf',
  },
  {
    name: 'Valor Econômico',
    logo: LogoValor,
    desc: 'CredPago, o avalista imobiliário que atraiu BTG e Bruno Gagliasso, toma a dianteira',
    link: '/midia/ValorEconomico_PDF.pdf',
  },
];

Swiper.use([Navigation, Pagination]);

export default function CredPagoNaMidia() {
  const swiper = useRef(null);
  const [height, setHeight] = useState('inherit');

  useEffect(() => {
    swiper.current = new Swiper('.swiper-container-midia', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination-midia',
        type: 'bullets',
        dynamicBullets: true,
        dynamicMainBullets: 3,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 4,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        900: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      on: {
        init: function (sw) {
          let { offsetHeight } = sw.$wrapperEl[0];
          setHeight(offsetHeight);
        },
      },
    });
  }, []);

  return (
    <MidiaWrapper id="credpago-na-midia">
      <Container>
        <UI.Row>
          <UI.Col responsive={{ xs: 12 }} style={{ textAlign: 'center' }}>
            <UI.Title level={3} fontWeight="700" splitLabel={['CredPago', 'na mídia']} />
          </UI.Col>
        </UI.Row>
        <UI.Row>
          <UI.Col responsive={{ xs: 12 }}>
            <div className="swiper-container swiper-container-midia">
              <div className="swiper-wrapper" style={{ height: `${height + 30}px` }}>
                {midiaItems.map((item, index) => {
                  return (
                    <div className="swiper-slide" key={index}>
                      <SlideText>
                        <div className="logo-wrapper">
                          <img className="logo" src={item.logo} alt={item.name} />
                        </div>
                        <p>{item.desc}</p>
                        <a title={`${item.name} - ${item.desc}`} href={item.link} target="_blank" rel="noreferrer">
                          Leia a matéria completa
                        </a>
                      </SlideText>
                    </div>
                  );
                })}
              </div>
              <div className="swiper-pagination swiper-pagination-midia"></div>
            </div>
          </UI.Col>
        </UI.Row>
      </Container>
    </MidiaWrapper>
  );
}
