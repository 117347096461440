import styled from 'styled-components';

import UI from '#front-credpago';

export const TabsContainer = styled(UI.Col)`
  li {
    button {
      font-weight: 400 !important;
    }

    &.is-active {
      button {
        font-size: 1.11rem;
        font-weight: 600 !important;
      }
    }
  }
`;

export const QueroSerParceiraContainer = styled.div`
  width: 336px;
  height: 287px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 31px;
  opacity: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p {
    &.title {
      text-align: center;
      font: normal normal 900 18px/21px Uni Neue;
      letter-spacing: 0.36px;
      color: #001c4a;
      opacity: 1;
    }

    &.description {
      text-align: center;
      font: normal normal normal 15px/22px Roboto;
      letter-spacing: 0.17px;
      color: #7d7d7d;
      opacity: 1;
      margin-top: 0;
    }
  }
`;
