import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import UI from '#front-credpago';

import { ReactComponent as SvgArrowDown } from '../../images/svg/arrow-down.svg';
import { ButtonWrapper, Container, FixedBottom, FullWidthWrapper } from './styles';

export const Hero = ({ data }) => {
  return (
    <FullWidthWrapper smallOnMdScreen={true}>
      <Container>
        <UI.Row>
          <UI.Col className="text-wrapper" responsive={{ xs: 12, md: 6, lg: 6 }}>
            <UI.Title level={1} fontWeight={700} splitLabel={['Aluguel sem fiador,', 'rápido e garantido']} />
            <p>
              Com CredPago todo mundo <b>aluga sem fiador</b>. <br />A <b>imobiliária</b> não exige, <br />o{' '}
              <b>inquilino</b> não depende e <br />o <b>proprietário</b> se garante.
            </p>
            <UI.Button
              onClick={() => navigate('/aluguel-sem-fiador')}
              style={{ width: '100%', maxWidth: 210 }}
              size="large"
            >
              Como assim?
            </UI.Button>
          </UI.Col>
          <UI.Col responsive={{ xs: 12, md: 6, lg: 6 }}>
            <Img fluid={data.Hero.childImageSharp.fluid} className="margin-top-col" />
          </UI.Col>
        </UI.Row>
      </Container>

      <FixedBottom>
        <Container>
          <UI.Row>
            <UI.Col>
              <ButtonWrapper>
                <UI.Button
                  ariaLabel="Role pra baixo"
                  size="small"
                  icon={<SvgArrowDown />}
                  onClick={() => navigate('/#vantagens')}
                  type="light"
                />
                <span>Role pra baixo</span>
              </ButtonWrapper>
            </UI.Col>
          </UI.Row>
        </Container>
      </FixedBottom>
    </FullWidthWrapper>
  );
};

export default Hero;
