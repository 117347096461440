import Img from 'gatsby-image';
import React from 'react';

import UI from '#front-credpago';

const Proprietarios = ({ img }) => {
  return (
    <UI.Row className="tab-proprietarios" style={{ alignItems: 'center' }}>
      <UI.Col responsive={{ xs: 12, sm: 6, lg: 4 }}>
        <UI.Title level={5} fontWeight={600}>
          Proprietários
        </UI.Title>
        <UI.Title level={6} fontWeight={400} style={{ maxWidth: 300 }}>
          Não atuamos direto com proprietários, mas fique tranquilo, estamos nas melhores imobiliárias.
        </UI.Title>
        <ul>
          <li>Agilidade para alugar</li>
          <li>Recebimento garantido dos aluguéis</li>
          <li>Repasse rápido dos valores em caso de inadimplência</li>
          <li>Análise de crédito eficiente para diminuir inadimplência</li>
        </ul>

        <p>A fiança CredPago está de acordo com a Lei 8245. A Lei do Inquilinato.</p>
      </UI.Col>
      <UI.Col responsive={{ xs: 12, sm: 6, lg: 4 }}>
        <div className="img-wrapper">
          <Img fluid={img} loading="lazy" />
        </div>
      </UI.Col>
    </UI.Row>
  );
};

export default Proprietarios;
