import 'swiper/swiper.min.css';

import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper';

import UI from '#front-credpago';

import PicFernanda from '../../images/depoimentos/fernanda-antunes.jpg';
import PicFernando from '../../images/depoimentos/fernando-lima.jpg';
import PicMatheus from '../../images/depoimentos/matheus-pedro.jpg';
import PicMurilo from '../../images/depoimentos/murilo-vargas.jpg';
import PicPaulo from '../../images/depoimentos/paulo-gallo.jpg';
import PicRaphaela from '../../images/depoimentos/raphaela-paitch.jpg';
import PicVinicius from '../../images/depoimentos/vinicius-costa.jpg';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg';
import { Container, DepoimentosWrapper, DepoimentoWrapper } from './style';

SwiperCore.use([Navigation]);

const depos = [
  {
    nome: 'Fernando Lima',
    descricao: 'Corretor de Locação da França Imobiliária',
    citacao:
      'Antes, alugar um imóvel era uma burocracia. Um exemplo claro é que ninguém quer ser fiador. Mas desde que conhecemos a CredPago tudo ficou fácil, prático e ágil. Mudou a nossa forma de alugar.',
    foto: PicFernando,
  },
  {
    nome: 'Paulo Rogério Gallo',
    descricao: 'Diretor da Dupla Imóveis',
    citacao:
      'Ser parceiro CredPago faz a diferença. A empresa é sinônimo de praticidade e segurança. A número um das garantias locatícias em nossa imobiliária. Uma opção rápida, fácil e sem papelada.',
    foto: PicPaulo,
  },
  {
    nome: 'Vinicius Costa',
    descricao: 'CEO Ivan Negócios Imobiliários',
    citacao:
      'A CredPago é uma das nossas maiores parceiras. A empresa, além de somar com nosso negócio, ampliou e muito nossos números. Traz seriedade, agilidade, segurança e confiança em tudo o que propõe.',
    foto: PicVinicius,
  },
  {
    nome: 'Matheus Pedro',
    descricao: 'Corretor de Locação e Embaixador CredPago na Fácil Imóveis',
    citacao:
      'A CredPago veio para agregar, trazer mais clientes e facilitar processos. Cerca de 90% dos nossos contratos são CredPago. É uma garantia segura e comprovada que só traz benefícios.',
    foto: PicMatheus,
  },
  {
    nome: 'Murilo Niklas Vargas',
    descricao: 'Coordenador de Locações da Imobiliária Junqueira',
    citacao:
      'Somos parceiros da CredPago há dois anos e, desde então, nossos números crescem diariamente de forma mais ágil e com maior satisfação dos clientes. Mais de 60% das nossas locações tem garantia CredPago.',
    foto: PicMurilo,
  },
  {
    nome: 'Fernanda Antunes',
    descricao: 'Consultora de Locação na Ibagy Unidade Palhoça',
    citacao:
      'Desde que começamos a utilizar a CredPago nosso trabalho melhorou. Quando um cliente chega o que ele menos quer é burocracia, e o que ele mais quer é se mudar rapidamente. Com a CredPago isso é possível.',
    foto: PicFernanda,
  },
  {
    nome: 'Raphaela Fernanda Paitch',
    descricao: 'Consultora de Locação na Lokatell',
    citacao:
      'Trabalhar com a CredPago é muito simples, fácil de utilizar e de explicar para os clientes. Se você busca agilidade, segurança e não quer mais depender do fiador, a empresa é a melhor opção.',
    foto: PicRaphaela,
  },
];

const Depoimento = ({ nome, descricao, foto, citacao }) => {
  return (
    <>
      <DepoimentoWrapper>
        <img decoding="async" src={foto} width="96" height="96" alt={nome} />
        <UI.Title level={5} fontWeight={800}>
          {nome}
        </UI.Title>
        <UI.Title level={6}>{descricao}</UI.Title>
        <blockquote>
          <p>{citacao}</p>
        </blockquote>
      </DepoimentoWrapper>
    </>
  );
};

const Depoimentos = () => {
  const swiper = useRef(null);

  useEffect(() => {
    //add necessary parameters required by checking the offical docs of swiper
    swiper.current = new Swiper('.swiper-container-depoimentos', {
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 7000,
      },
      initialSlide: 1,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerView: 3,
      navigation: {
        nextEl: '.swiper-button-depo-next',
        prevEl: '.swiper-button-depo-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        // when window width is >= 480px
        800: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    });
  }, []);

  return (
    <DepoimentosWrapper id="depoimento">
      <Container>
        <UI.Row>
          <UI.Col responsive={{ xs: 12 }}>
            <UI.Title style={{ textAlign: 'center' }} level={3} fontWeight={600} className="title">
              Depoimentos
            </UI.Title>

            <div className="swiper-container swiper-container-depoimentos">
              <div className="swiper-wrapper">
                {depos.map((item, idx) => (
                  <div className="swiper-slide" key={idx}>
                    <Depoimento nome={item.nome} descricao={item.descricao} foto={item.foto} citacao={item.citacao} />
                  </div>
                ))}
              </div>
              <div className="swiper-buttons-nav swiper-button-depo-prev">
                <UI.Button ariaLabel="Depoimento Anterior" type="light" icon={<ArrowLeft />} />
              </div>
              <div className="swiper-buttons-nav swiper-button-depo-next">
                <UI.Button ariaLabel="Próximo Depoimento" type="light" icon={<ArrowRight />} />
              </div>
            </div>
          </UI.Col>
        </UI.Row>
      </Container>
    </DepoimentosWrapper>
  );
};

export default Depoimentos;
