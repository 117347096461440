import Img from 'gatsby-image';
import React from 'react';

import UI from '#front-credpago';

import { LogoLoftCredpago } from '../../logos/LogoLoftCredpago';
import { BlocoBtgWrapper, Container } from '../style';
import * as S from './styles';

const BlocoBtg = ({ data }) => {
  return (
    <BlocoBtgWrapper id="btg">
      <Container>
        <UI.Row>
          <UI.Col responsive={{ xs: 12, md: 6, lg: 6 }}>
            <S.PictureParceriaEmpoderar>
              <Img fluid={data.ParceriaEmpoderar.childImageSharp.fluid} />
            </S.PictureParceriaEmpoderar>
          </UI.Col>
          <UI.Col responsive={{ xs: 12, md: 6, lg: 6 }}>
            <div className="text-wrapper">
              <UI.Title
                level={2}
                fontWeight={700}
                splitLabel={['Uma parceria para empoderar ainda mais', <br key={0} />, null, 'as imobiliárias']}
                style={{ marginBottom: 30, textAlign: 'right' }}
                className="text-title"
              />
              <UI.Title level={6}>
                Em julho de 2021, CredPago e Loft se uniram para empoderar imobiliárias e corretores com um portfólio
                completo de produtos.
              </UI.Title>
              <p>
                Juntas, seguiremos apostando no conhecimento local das imobiliárias e no trabalho especializado dos
                corretores para criar inovações para todas as pontas da locação residencial.
              </p>
              <div className="logo-wrapper">
                <LogoLoftCredpago />
              </div>
            </div>
          </UI.Col>
        </UI.Row>
      </Container>
    </BlocoBtgWrapper>
  );
};

export default BlocoBtg;
