import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import UI from '#front-credpago';

const Inquilinos = ({ img }) => {
  return (
    <UI.Row style={{ alignItems: 'center' }}>
      <UI.Col responsive={{ xs: 12, sm: 6, lg: 4 }}>
        <UI.Title level={5} fontWeight={600}>
          Inquilinos
        </UI.Title>
        <UI.Title level={6} fontWeight={400} style={{ maxWidth: 200 }}>
          Liberdade para alugar 100% digital
        </UI.Title>
        <ul>
          <li>Sem fiador</li>
          <li>Sem caução</li>
          <li>Sem comprovação de renda</li>
          <li>Análise instantânea</li>
          <li>Parcelamento em até 12X</li>
        </ul>

        <UI.Button
          size="large"
          style={{ width: 'auto', marginTop: 24 }}
          blocked
          onClick={() => navigate('/aluguel-sem-fiador#como-funciona')}
        >
          Quero alugar sem fiador
        </UI.Button>
      </UI.Col>
      <UI.Col responsive={{ xs: 12, sm: 6, lg: 4 }}>
        <div className="img-wrapper">
          <Img fluid={img} loading="lazy" />
        </div>
      </UI.Col>
    </UI.Row>
  );
};

export default Inquilinos;
